import { ENewUserType } from "../constants";
import { ELoginPageViewType } from "./login/Login";

export interface ILoginResponse {
  token: string;
  refreshToken: string;
}

export interface IUser {
  id: string;
  email: string | null;
  name: string | null;
  profileImage: string | null;
  phoneNumber?: string | null;
  userTextNotificationsEnabled: boolean;
  carrierTextNotificationsEnabled: boolean;
  preferredName: string | null;
  isInternalUser?: boolean;
  shouldDisplayFeedbackSurvey: boolean;
  calendarProvider?: string | null;
  proactiveReminders: IProactiveReminders;
  signUpType?: ENewUserType;
  isActive?: boolean;
  canRegister?: boolean;
  clientFlags?: number;
}

export interface IProactiveReminders {
  proactiveDailyReminderHour: number | null; // 0-23
  proactiveEveningReminderHour: number | null; // 0-23
  isProactiveWorkBlockCheckInReminderEnabled: boolean;
}

export enum ELoginType {
  GOOGLE = 'google',
  APPLE = 'apple',
  DISCORD = 'discord'
}

export interface ILoginReqPayload {
  accessToken: string;
  redirectUri: string;
  error: string;
  phoneNumber?: string | null;
  email?: string | null;
}

export interface IRegisterPayload {
  registrationCode: string;
  registrationParameters?: string | null;
}
export class RegisterForm {
  registrationCode = '';
}
export class AddPhoneRegistrationForm {
  userPhone = '';
}
export interface IRegisterPhoneNumberRequestPayload {
  phoneNumber?: string;
  phoneCountryTwoLetterCode?: string;
}

export interface ICalendarProvider {
  provider: string;
}

export enum ECalendarSyncType {
  FULL = 1,
  EXPORT,
  IMPORT
}

export enum EExternalCalendarProvider {
  GOOGLE = 'google',
  OUTLOOK = 'outlook'
}

export interface IVerifyOtpPayload {
  phoneNumber?: string;
  otp?: string;
  phoneCountryTwoLetterCode?: string;
}

export interface LoginPageProps {
  userType: ENewUserType;
  viewType: ELoginPageViewType;
}

export interface IMagicLinkReqPayload {
  phoneNumber?: string;
  phoneCountryTwoLetterCode?: string;
  email?: string;
  redirectUri: string;
  registrationParameters?: string;
  isShortCodeRequested: boolean
}