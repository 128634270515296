import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/store'
import { ETaskStatus, IAddEditTaskForm, ICreateTaskReqPayload, ITaskUpdateReqPayload, ITaskFields, IMessageDataTask, ISubTask, ITaskTag, IRecurrenceValues, ETaskSource, ETaskFormType, ETasksGroupsType, IPlannerCurrentTaskPlacement } from '../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import closeModalDeskIcon from "../../../../../../assets/images/close-create-task-modal-desk.svg";
import closeModalMobileIcon from "../../../../../../assets/images/close-create-task-modal-mobile.svg";
import trashMobileIcon from "../../../../../../assets/images/add-edit-trash-mobile.svg";
import backIcon from "../../../../../../assets/images/thicker-2px-stroke-back-icon.svg";
import { FormProvider, UseFieldArrayRemove, UseFieldArrayUpdate, useFieldArray, useForm } from 'react-hook-form';
import SchedulingSection from './scheduling-section/SchedulingSection';
import { ReactComponent as DueDateIconComponent } from '../../../../../../assets/images/add-edit-task-due-date-icon.svg';
import { ReactComponent as WorkTimeIconComponent } from '../../../../../../assets/images/add-edit-task-work-time-icon.svg';
import { getDateAndTimeWithoutYear, getDateWithoutYear, getTimeByDate } from '../../../../../../shared/utils/dateFormat';
import { createTaskReqAction, getTasksListReqAction, setCalendarSelectedWorkTime, setShouldOpenAddEditTaskFrom, setShouldStartDestroyAnimationTaskForm, setUpdatedTasksViaTheChat, updateTaskReqAction } from '../stageTasks.store';
import { ConfirmModal } from '../../../../../../shared/components/confirm-modal/ConfirmModal';
import { useLocalStorage } from '../../../../../../shared/utils/useLocalStorage';
import { chatSessionIdSessionStorageKey, slideUpFullScreenMobilePopoverClassName, subTaskOverlayPopoverClassName, taskDueDateDefaultTime, taskFormParam } from '../../../../../../app/constants';
import createAppOverlayPopover from '../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { TaskEditOverlay } from '../task-edit-overlays/TaskEditOverlay';
import { setQueueMessageData } from '../../../../chat.store';
import { batch } from 'react-redux';
import { createAutomatedTaskEvent } from '../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import TaskTags from './task-tags/TaskTags';
import TaskNotes from './task-notes/TaskNotes';
import TaskDescription from './task-description/TaskDescription';
import SubtaskDetailsModal from './subtask-details-modal/SubtaskDetailsModal';
import SubtasksSection from './subtasks-section/SubtasksSection';
import TaskNameController from './task-name-controller/TaskNameController';
import { editOverlaysPositionStyle, getElementPosition, getElementWidthAndHeight, plannerTaskContextOverlayStyle, taskFormToTaskEntity } from './CreateOrEditTask.utils';
import { setScratchpadTaskUpdatedItem } from '../../stage-scratchpad/stageScratchpad.store';
import { setClickNavbarConfirmModalText } from '../../../../../../shared/store/shared.store';
import { useSearchParams } from 'react-router-dom';
import SingleTag from './task-tags/SingleTag';
import EventExternalSourceDetails from './event-external-source-details/EventExternalSourceDetails';
import ExternalEventsRecurrenceAndReminder from './external-events-recurrence-and-reminder/ExternalEventsRecurrenceAndReminder';
import './CreateOrEditTask.scss';
import { StagePlanner } from '../../stage-planner/StagePlanner';
import removeAppOverlayPopover from '../../../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { activatePlannerTimePicker, resetPlannerState } from '../../stage-planner/stagePlanner.utils';
import { EPlannerClickEvent } from '../../stage-planner/stagePlanner.interface';
import { isDesktopView } from '../../../../../../shared/utils/utils';
import { isMobileDevice } from '../../../../../../shared/utils/isMobileDevice';
import { EPlannerMode } from '../../stage-planner/stagePlanner.store';
import { setPlannerCurrentTaskPlacement, setPlannerOutputEventType, setPlannerOutputPayload } from '../../stage-planner/stagePlanner.store';
import SelectWorkTimeUsingCalendarPlanner from './select-work-time-using-calendar-planner/SelectWorkTimeUsingCalendar';
import { uxEventAddTaskOrSubTask } from '../../../../../../shared/utils/uxEventsUtils';
import { EAPIStatus } from '../../../../../../shared/api/models';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

interface IProps {
  itemForEditing: ISubTaskForEditing | null,
  onUpdateItemForEditing: (updatedTask: IMessageDataTask | null) => void
  isSubTask?: boolean;
  isIncludeSubtasks?: boolean;
  onRemoveSubTaskFromParentFieldArray?: UseFieldArrayRemove;
  onCloseModal?: () => void;
  onUpdateSubTaskInParentFieldArr?: UseFieldArrayUpdate<IAddEditTaskForm, "subTasks">;
  parentName?: string;
  parentFirstTag?: ITaskTag;
}

export interface ISubTaskForEditing extends ISubTask {
  indexInParentStepsArr?: number
}

type TAnimationClassName = 'fadeInSlideUp' | 'slideOutDown' | 'endSlideAnimation';

const CreateOrEditTask = ({ itemForEditing, onUpdateItemForEditing, onRemoveSubTaskFromParentFieldArray, onCloseModal, onUpdateSubTaskInParentFieldArr, isSubTask = false, isIncludeSubtasks = false, parentName, parentFirstTag }: IProps) => {
  const isEditModeOfExistingTask = !!itemForEditing;
  const { shouldOpenAddEditTaskFrom, calendarSelectedWorkTime, updateTaskRes, updatedTasksViaTheChat, tasksOrderedByGroups, shouldStartDestroyAnimationTaskForm } = useAppSelector(store => store.StageTasksReducer);
  const { plannerMode, plannerOutputEventType, plannerOutputPayload } = useAppSelector(store => store.StagePlannerReducer);
  // if it's external event -> the form should be read only
  const isReadOnly = (itemForEditing?.isEvent && itemForEditing.source !== ETaskSource.Internal) || false;
  const isEvent = itemForEditing?.isEvent || shouldOpenAddEditTaskFrom === ETaskFormType.Event;
  const [isScratchpadItem,] = useState<boolean>(!!itemForEditing && itemForEditing?.status === ETaskStatus.SCRATCHPAD);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldDisplayDeleteConfirmModal, setShouldDisplayDeleteConfirmModal] = useState(false);
  const [subtaskForEditing, setSubtaskForEditing] = useState<ISubTaskForEditing | null>(null);
  const [sessionIdLocalStorage,] = useLocalStorage(chatSessionIdSessionStorageKey, '');
  const { sessionId } = useAppSelector(store => store.chatReducer);
  const [animationClassName, setAnimationClassName] = useState<TAnimationClassName>('fadeInSlideUp');
  const [, setSearchParams] = useSearchParams();
  const [timePickerMode, setTimePickerMode] = useState<boolean>(false);
  const [workTimeTaskId, setWorkTimeTaskId] = useState<string | undefined>(undefined);
  const [isTimePickerMultipleWorkTime, setIsTimePickerMultipleWorkTime] = useState<boolean>(false);
  // 30 minutes
  const defaultEventDuration = 30 * 60;


  const getFormDataForReset = (editingItem?: ISubTaskForEditing | null) => {
    const editingItemData = !!editingItem ? editingItem : itemForEditing;
    return ({
      name: editingItemData?.name || "",
      dueDate: editingItemData?.dueDate ? new Date(editingItemData.dueDate) : undefined,
      // when create event set default of 30 minutes
      duration: (!isEditModeOfExistingTask && isEvent) ? defaultEventDuration : editingItemData?.duration ? editingItemData.duration : undefined,
      workTime: editingItemData?.workTime ? new Date(editingItemData.workTime) : (!isEditModeOfExistingTask && !!calendarSelectedWorkTime) ? new Date(calendarSelectedWorkTime) : undefined,
      isDone: editingItemData?.status === ETaskStatus.DONE,
      priority: editingItemData?.priority || false,
      quickWin: editingItemData?.quickWin || false,
      description: editingItemData?.description || null,
      tags: editingItemData?.tags ? [...editingItemData.tags] : [],
      dueDateReminder: editingItemData?.dueDateReminder,
      workTimeReminder: editingItemData?.workTimeReminder,
      notes: editingItemData?.notes || null,
      recurrenceValues: getInitializeSelectedRecurrenceValues(editingItemData),
      workBlockId: editingItemData?.workBlockId || null,
      workBlockInstance: editingItemData?.workBlockInstance || null
    })
  }
  const taskForm = useForm<IAddEditTaskForm>({
    defaultValues: {
      ...getFormDataForReset(itemForEditing),
      subTasks: getSubTasksArray() || [],
      multiWorkTimeSubTasks: getWorkTimeSubTasksArray() || [],
    }
  });

  const { fields: subTasksFields, append: subTasksAppend, remove: subTasksRemove, update: subTasksUpdate } = useFieldArray({
    control: taskForm.control,
    name: 'subTasks',
  });

  const { fields: multiWorkTimeSubTasks, append: multiWorkTimeSubTasksAppend, remove: multiWorkTimeSubTasksRemove, update: multiWorkTimeSubTasksUpdate } = useFieldArray({
    control: taskForm.control,
    name: 'multiWorkTimeSubTasks',
  });

  const updateSubTaskOrSubWorkTimeTask = useCallback((taskToUpdate: IMessageDataTask): boolean => {
    const multiWorkTimeIndex = taskForm.getValues()?.multiWorkTimeSubTasks?.findIndex(t => t.taskId === taskToUpdate?.id);
    if (multiWorkTimeIndex !== undefined && multiWorkTimeIndex >= 0) {
      // if the updated task status is DELETED remove this sub work time task from the multiWorkTime arr, else update the subTask in the multiWorkTime arr
      if (taskToUpdate.status === ETaskStatus.DELETED) multiWorkTimeSubTasksRemove(multiWorkTimeIndex);
      else multiWorkTimeSubTasksUpdate(multiWorkTimeIndex, { ...taskToUpdate, taskId: taskToUpdate.id })
      return true;
    }
    else {
      const subTaskIndex = taskForm.getValues()?.subTasks?.findIndex(t => t.taskId === taskToUpdate?.id);
      if (subTaskIndex !== undefined && subTaskIndex >= 0) {
        // if the updated task status is DELETED remove this subtask from the subTasks arr, else update the subTask in the subTasks arr
        if (taskToUpdate.status === ETaskStatus.DELETED) subTasksRemove(subTaskIndex);
        else subTasksUpdate(subTaskIndex, { ...taskToUpdate, taskId: taskToUpdate.id });
        return true;
      }
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addNewSubTaskOrSubWorkTimeTask = useCallback((taskToCreate: IMessageDataTask) => {
    // if the sun task includes name insert to the subTasks arr else insert it to the workTime sub tasks array
    if (!taskToCreate?.name || taskToCreate?.name.length <= 0 || taskToCreate.isMultiWorkTimeTask) multiWorkTimeSubTasksAppend({ ...taskToCreate, taskId: taskToCreate.id });
    else subTasksAppend({ ...taskToCreate, taskId: taskToCreate.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetFormWithNewData = (editingItem?: ISubTaskForEditing | null) => {
    taskForm.reset({
      ...taskForm.getValues(),
      ...getFormDataForReset(editingItem)
    })
  }

  // update the form in case the user change the task via the chat while the form is opened
  useEffect(() => {
    if (!!updatedTasksViaTheChat) {
      updatedTasksViaTheChat.forEach(taskToUpdate => {
        if (taskToUpdate.id === itemForEditing?.taskId || taskToUpdate?.parentId === itemForEditing?.taskId) {
          // if the updated task id the current editing task - update the editing task
          if (taskToUpdate.id === itemForEditing?.taskId) {
            // if the form is belong to a subTask
            if (isSubTask) {
              onUpdateSubtask({ ...taskToUpdate, taskId: taskToUpdate.id });
              resetFormWithNewData(taskToUpdate);
              return;
            }
            else if (taskToUpdate.status === ETaskStatus.DELETED) {
              onClose(true);
              return;
            }
            else {
              resetFormWithNewData(taskToUpdate);
              onUpdateItemForEditing(taskToUpdate);
            }
          }
          // if the updated task is child of the editing item (multiWorkTimeSubTask or subTask)
          else if (!isSubTask && taskToUpdate?.parentId === itemForEditing?.taskId) {
            const isSubTaskUpdated = updateSubTaskOrSubWorkTimeTask(taskToUpdate);
            // if the subTask not updated -> means this task not found and we need to add a new one
            if (!isSubTaskUpdated) addNewSubTaskOrSubWorkTimeTask(taskToUpdate);
          }
        }
      })
      dispatch(setUpdatedTasksViaTheChat(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedTasksViaTheChat])

  function getInitializeSelectedRecurrenceValues(editingItem?: ISubTaskForEditing | null) {
    const editingItemData = !!editingItem ? editingItem : itemForEditing;
    return ({
      workTimeRecurrenceType: editingItemData?.workTimeRecurrenceType || null,
      workTimeRecurrenceInterval: editingItemData?.workTimeRecurrenceInterval || null,
      workTimeRecurrenceWeekDays: editingItemData?.workTimeRecurrenceWeekDays || null
    })
  }

  useEffect(() => {
    // to ensure the AppEventListenerHandler component render its focus/blur app events on the controllers in this CreateOrEditTask component
    setSearchParams(prev => {
      prev.delete(taskFormParam);
      prev.append(taskFormParam, 'open');
      return prev;
    });
    if (shouldStartDestroyAnimationTaskForm) setAnimationClassName('slideOutDown');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldStartDestroyAnimationTaskForm]);

  useEffect(() => {
    if (plannerOutputEventType && plannerOutputPayload && timePickerMode) {
      handlePlannerOutputEvent(plannerOutputEventType, plannerOutputPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plannerOutputEventType, plannerOutputPayload]);

  useEffect(() => {
    return () => {
      handleClosePlannerAsCalendar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocalFormWorkTime = (workTimeDate?: Date, workBlockId?: string | null, workBlockInstance?: Date, duration?: number | null, workBlockOrder?: number | null ) => {
    taskForm.setValue('workTime', workTimeDate);
    taskForm.setValue('workBlockId', workBlockId || null);
    taskForm.setValue('workBlockInstance', workBlockInstance || null);
    taskForm.setValue('duration', duration || null);
    taskForm.setValue('workBlockOrder', (!workBlockOrder && workBlockOrder !== 0) ? null : workBlockOrder);
    if (!!workBlockId) {
      taskForm.setValue('workTimeReminder', null);
      taskForm.setValue('recurrenceValues', {
        workTimeRecurrenceType: null,
        workTimeRecurrenceInterval: null,
        workTimeRecurrenceWeekDays: null
      });
    }
  }

  const assignPlannerWorkTime = (plannerOutputPayload: IPlannerCurrentTaskPlacement) => {
    const taskWorkTimeDate = plannerOutputPayload?.workTime ? new Date(plannerOutputPayload.workTime) : undefined;
    const workBlockInstance = plannerOutputPayload?.workBlockInstance ? new Date(plannerOutputPayload.workBlockInstance) : undefined;
    const duration = !!plannerOutputPayload.duration ? plannerOutputPayload.duration : null;
    if (!itemForEditing) {
      if (isTimePickerMultipleWorkTime) {
        // todo handle multiple work time for in create mode
      } else {
        updateLocalFormWorkTime(taskWorkTimeDate, plannerOutputPayload?.workBlockId, workBlockInstance, duration, plannerOutputPayload.workBlockOrder);
      }
    }
    else {
      if (isTimePickerMultipleWorkTime) {
        handleAddOrUpdateMultipleWorkTime(taskWorkTimeDate, plannerOutputPayload?.workBlockId, workBlockInstance, duration, plannerOutputPayload.workBlockOrder);
      } else {
        let assignToWorkBlockUpdated: ITaskFields = {};
        if (!!plannerOutputPayload?.workBlockId) {
          assignToWorkBlockUpdated = {
            workBlockId: plannerOutputPayload.workBlockId,
            workBlockInstance: !!workBlockInstance ? workBlockInstance.toISOString() : null,
            workTimeReminder: null,
            workTimeRecurrenceType: null
          }
        }
        onUpdateTask({ workTime: !!taskWorkTimeDate ? (taskWorkTimeDate as Date).toISOString() : null, duration, ...assignToWorkBlockUpdated, workBlockOrder: plannerOutputPayload.workBlockOrder });
        updateLocalFormWorkTime(taskWorkTimeDate, plannerOutputPayload?.workBlockId, workBlockInstance, duration, plannerOutputPayload.workBlockOrder);
      }
    }
  }

  const handlePlannerOutputEvent = (plannerOutputEventType: EPlannerClickEvent, plannerOutputPayload: unknown) => {
    removeAppOverlayPopover();
    if (!plannerOutputEventType || !plannerOutputPayload) return;
    switch (plannerOutputEventType) {
      case EPlannerClickEvent.TIME_PICKER:
        assignPlannerWorkTime(plannerOutputPayload as IPlannerCurrentTaskPlacement);
        break;
      default:
        return;
    }
    handleClosePlannerAsCalendar();
  }


  const dueDateWatch = taskForm.watch("dueDate");
  const durationWatch = taskForm.watch("duration");
  const workTimeWatch = taskForm.watch("workTime");
  const dueDateReminderWatch = taskForm.watch("dueDateReminder");
  const workTimeReminderWatch = taskForm.watch("workTimeReminder");
  const recurrenceValuesWatch = taskForm.watch("recurrenceValues");
  const nameWatch = taskForm.watch("name");

  function getSubTasksArray(): ISubTask[] {
    if (!isEditModeOfExistingTask || !isIncludeSubtasks) return [];
    return (
      Object.values(tasksOrderedByGroups || {})
        .flat()
        .filter(item => item?.parentId === itemForEditing?.taskId && item.status !== ETaskStatus.DELETED && !item.isMultiWorkTimeTask)
        .map(item => ({
          ...item,
          taskId: item.id
        }))
        .sort((a, b) => new Date(a.creationTime || "").getTime() - new Date(b.creationTime || "").getTime())
    )
  }

  function getWorkTimeSubTasksArray(): ISubTask[] {
    if (!isEditModeOfExistingTask || !isIncludeSubtasks) return [];
    return (
      Object.values(tasksOrderedByGroups || {})
        .flat()
        .filter(item => item?.parentId === itemForEditing?.taskId && item.status !== ETaskStatus.DELETED && item.isMultiWorkTimeTask)
        .map(item => ({
          ...item,
          taskId: item.id
        }))
        .sort((a, b) => new Date(a.creationTime || "").getTime() - new Date(b.creationTime || "").getTime())
    )
  }

  const onClose = (isDeletedBeforeClosing?: boolean) => {
    batch(() => {
      if (isScratchpadItem && !!itemForEditing) {
        dispatch(setScratchpadTaskUpdatedItem({ ...itemForEditing, status: isDeletedBeforeClosing ? ETaskStatus.DELETED : itemForEditing.status }));
      }
      onUpdateItemForEditing(null);
      dispatch(setClickNavbarConfirmModalText(null));
      setAnimationClassName('slideOutDown');
      setSearchParams(prev => {
        prev.delete(taskFormParam);
        return prev;
      });
    });
  };

  const onSubmitCreateNewTask = () => {
    // only in create
    if (!isEditModeOfExistingTask && !shouldSaveBtnBeDisabled()) {
      const formData = taskForm.getValues();
      // call to create-task request
      const reqPayload: ICreateTaskReqPayload = {
        sessionId: sessionId?.data?.sessionId || sessionIdLocalStorage,
        name: formData.name,
        dueDate: formData.dueDate ? formData.dueDate.toISOString() : null,
        duration: formData.duration,
        dueDateReminder: formData.dueDateReminder || null,
        workTime: formData.workTime ? formData.workTime.toISOString() : null,
        workTimeReminder: formData.workTimeReminder || null,
        status: formData.isDone ? ETaskStatus.DONE : ETaskStatus.NOT_STARTED,
        priority: formData?.priority || false,
        quickWin: formData?.quickWin || false,
        description: formData.description || null,
        notes: formData.notes || null,
        tags: formData.tags || [],
        workBlockId: formData?.workBlockId || null,
        workBlockInstance: formData?.workBlockInstance || null,
        workBlockOrder: (!formData?.workBlockOrder && formData?.workBlockOrder !== 0) ? null :formData?.workBlockOrder,
        ...formData.recurrenceValues
      }
      if (isIncludeSubtasks) {
        const cleanedSteps = formData?.subTasks ? (formData.subTasks as ISubTaskForEditing[]).map(({ id, indexInParentStepsArr, parentId, taskId, ...rest }) => rest) as ISubTask[] : null;
        reqPayload.steps = cleanedSteps;
      }
      if (isEvent) {
        reqPayload.isEvent = true;
        reqPayload.source = ETaskSource.Internal;
      }
      dispatch(createTaskReqAction(reqPayload)).unwrap().then((response) => {
        dispatch(getTasksListReqAction());
        if (response.length > 0 && !isSubTask && !isEvent) {
          const parentId = response.find(t => !!t.parentId)?.parentId;
          const parentTask = parentId ? response.find(t => t.id === parentId) || null : null;
          const siblings = parentId ? response.filter(t => t.parentId === parentId) : null;
          const task = parentTask ? parentTask : response[0];
          dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(uxEventAddTaskOrSubTask(parentTask, task, siblings)) }));
        }
        onClose();
      })
    }
  };

  const onUpdateTask = (updatedField: ITaskFields, shouldSendMsgToTheBot: boolean = true): void => {
    if (!isEditModeOfExistingTask) dispatch(setClickNavbarConfirmModalText(t("newTaskWillNotBeSavedNavbarConfirm")));
    const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;
    // if isSubTask and there is no parent id (the main task is still in create mode) -> update the local subTasks array
    if (isSubTask && !itemForEditing?.parentId) {
      onUpdateSubtask(updatedField as ISubTask);
      return;
    }
    else if (isEditModeOfExistingTask && !!itemForEditing?.taskId) {
      const reqPayload: ITaskUpdateReqPayload = Object.assign({}, { sessionId: currentSessionId, id: itemForEditing.taskId }, updatedField);
      dispatch(updateTaskReqAction(reqPayload)).unwrap()
        .then((updatedTask) => {
          batch(() => {
            if (!isScratchpadItem) dispatch(getTasksListReqAction());
            if (updatedField?.status === ETaskStatus.DELETED) onClose(true);
            else !!onUpdateItemForEditing && !isSubTask && onUpdateItemForEditing(updatedTask);
            if (isSubTask) onUpdateSubtask({ ...updatedTask, taskId: updatedTask.id });
          });
        })
    }
    if (shouldSendMsgToTheBot && itemForEditing) handleSendUXEvent(updatedField, itemForEditing, currentSessionId, itemForEditing?.indexInParentStepsArr);
  }

  const onUpdateSubtask = (updatedField: ISubTask) => {
    if (updatedField?.status === ETaskStatus.DELETED && !!onRemoveSubTaskFromParentFieldArray) {
      onRemoveSubTaskFromParentFieldArray(itemForEditing?.indexInParentStepsArr);
      onClose();
      return;
    }
    else if (!!onUpdateSubTaskInParentFieldArr && itemForEditing?.indexInParentStepsArr !== undefined) {
      onUpdateItemForEditing({ ...itemForEditing, ...updatedField } as IMessageDataTask);
      onUpdateSubTaskInParentFieldArr(itemForEditing?.indexInParentStepsArr, { ...itemForEditing, ...updatedField });
    }
  }

  const onChangeDueDateAndWorkTime = (fieldName: 'dueDate' | 'workTime', date: Date, reminder?: number | null, duration?: number | null, recurrenceUpdatedValues?: IRecurrenceValues, isWorkBlockCleared?: boolean) => {
    const reminderFieldName = fieldName === "dueDate" ? "dueDateReminder" : "workTimeReminder";
    const recurrenceProperties = !!recurrenceUpdatedValues ? { ...recurrenceUpdatedValues } : {};
    let workTimeFieldsForUpdate: ITaskFields = { [reminderFieldName]: reminder || null, ...recurrenceProperties }
    // when main task workBlock cleared - remove it.
    if (!!isWorkBlockCleared) {
      updateLocalFormWorkTime(undefined);
      onUpdateTask({ workBlockId: null, workBlockInstance: null, duration: null, workBlockOrder: null });
      return;
    }
    // if creating or editing an event, don't send empty workTime date or duration to the server
    if (isEvent && fieldName === 'workTime') {
      if (!!date) workTimeFieldsForUpdate[fieldName] = (date as Date).toISOString();
      if (!!duration) workTimeFieldsForUpdate.duration = duration;
      onUpdateTask({ ...workTimeFieldsForUpdate });
    }
    else onUpdateTask(fieldName === 'workTime' ? { [fieldName]: date ? (date as Date).toISOString() : null, duration: duration != null && duration !== undefined ? duration : null, ...workTimeFieldsForUpdate } : { [fieldName]: date ? (date as Date).toISOString() : null, [reminderFieldName]: reminder || null });
    taskForm.setValue(fieldName, date || null);
    taskForm.setValue(reminderFieldName, reminder || null);
    taskForm.setValue("recurrenceValues", recurrenceProperties);
    if (fieldName === 'workTime') taskForm.setValue('duration', duration != null && duration !== undefined ? duration : null);
  }

  const handleSendUXEvent = (updatedField: ITaskFields, itemForEditing: ISubTaskForEditing | null, currentSessionId: string, subTaskIndex?: number) => {
    const fieldName = Object.keys(updatedField)[0];
    const taskBeforeUpdate = itemForEditing ? { ...itemForEditing } : taskFormToTaskEntity(taskForm, itemForEditing, subTaskIndex);
    delete taskBeforeUpdate.id;
    if (itemForEditing?.taskId) taskBeforeUpdate.id = itemForEditing.taskId;
    if (itemForEditing?.parentId) taskBeforeUpdate.parentId = itemForEditing.parentId;
    dispatch(setQueueMessageData({ type: 'automatic', botRequestJson: JSON.stringify(createAutomatedTaskEvent(currentSessionId, fieldName, updatedField[fieldName as keyof ITaskFields], taskBeforeUpdate as IMessageDataTask)) }));
  }

  const handlePlannerAsCalendar = (isTimePickerMultipleWorkTime?: boolean, taskId?: string) => {
    let task = null;
    if (!!itemForEditing && taskId === itemForEditing.taskId) task = { ...itemForEditing };
    else task = multiWorkTimeSubTasks.find(item => item.taskId === taskId) || { name: taskForm.getValues("name") || t("newTaskDefaultName"), parentId: taskForm.getValues("parentId"), duration: taskForm.getValues("duration") } as IPlannerCurrentTaskPlacement;
    dispatch(setPlannerCurrentTaskPlacement(task as IPlannerCurrentTaskPlacement));
    activatePlannerTimePicker();
    setIsTimePickerMultipleWorkTime(!!isTimePickerMultipleWorkTime);
    setTimePickerMode(true);
    setWorkTimeTaskId(taskId);
    removeAppOverlayPopover();
    createAppOverlayPopover(<SelectWorkTimeUsingCalendarPlanner onClose={handleClosePlannerAsCalendar} taskName={taskForm.getValues('name') ? taskForm.getValues('name') : isEvent ? 'New Event' : 'New Task'} />, null, null,
      plannerTaskContextOverlayStyle
      , { isCustomStyle: isDesktopView() || !isMobileDevice(), allowInteraction: true, closeOnClickOutside: false })
  }

  const handleClosePlannerAsCalendar = () => {
    removeAppOverlayPopover();
    if (plannerMode !== EPlannerMode.UNSCHEDULEDTASKSPLACER) resetPlannerState();
    setIsTimePickerMultipleWorkTime(false);
    setTimePickerMode(false);
    setWorkTimeTaskId(undefined);
    dispatch(setPlannerOutputEventType(undefined));
    dispatch(setPlannerOutputPayload(undefined));
  }

  const handleAddOrUpdateMultipleWorkTime = (date: Date | undefined, workBlockId?: string | null, workBlockInstance?: Date, duration?: number | null, workBlockOrder?: number|null) => {
    const currentSessionId = sessionId?.data?.sessionId || sessionIdLocalStorage;
    const reqPayload: ICreateTaskReqPayload = {
      sessionId: currentSessionId,
      parentId: itemForEditing?.taskId,
      name: null,
      workTime: (date && !workBlockId) ? date.toISOString() : null,
      status: ETaskStatus.NOT_STARTED,
      workBlockId: !!workBlockId ? workBlockId : null,
      workBlockInstance: !!workBlockInstance ? workBlockInstance.toISOString() : null,
      workBlockOrder: (!workBlockOrder && workBlockOrder !== 0) ? null : workBlockOrder,
    }
    if (!!duration) reqPayload.duration = duration;
    if (!!workBlockId) {
      reqPayload.workTimeReminder = null;
      reqPayload.workTimeRecurrenceType = null;
    }
    if (!workTimeTaskId) {
      dispatch(createTaskReqAction(reqPayload)).unwrap().then(data => {
        multiWorkTimeSubTasksAppend({ ...data[0], taskId: data[0].id });
        dispatch(getTasksListReqAction());
      })
    } else {
      const updateRequest = Object.assign({}, reqPayload, { id: workTimeTaskId });
      dispatch(updateTaskReqAction(updateRequest)).unwrap().then(data => {
        const index = multiWorkTimeSubTasks?.findIndex(t => t.taskId === workTimeTaskId);
        if (index !== undefined && index !== -1 && !!multiWorkTimeSubTasksUpdate) multiWorkTimeSubTasksUpdate(index, { ...data, taskId: data.id });
        dispatch(getTasksListReqAction());
      });
    }
  }

  const shouldSaveBtnBeDisabled = () => {
    return (
      !taskForm.formState.isValid ||
      taskForm.formState.isSubmitted ||
      (isEvent ? (!durationWatch || !workTimeWatch) : false)
    );
  }


  return (
    <div
      id={isSubTask ? `add-edit-task-form-container` : ''}
      className={`${!isSubTask ? 'details-dialog-animation-container ' + animationClassName : 'subtask-form-container'}`}
      onAnimationEnd={(animationData) => {
        if (animationData.animationName === 'slideOutDownAnimation') {
          //when animation end destroy the component
          !!onCloseModal && onCloseModal();
          dispatch(setShouldStartDestroyAnimationTaskForm(false));
          dispatch(setShouldOpenAddEditTaskFrom(null));
          dispatch(setCalendarSelectedWorkTime(null));
          dispatch(setPlannerCurrentTaskPlacement(null));
        }
        setAnimationClassName('endSlideAnimation');
      }}
    >
      <FormProvider {...taskForm}>
        <div className={`add-edit-task-container add-edit-task-container${isSubTask ? '--subtask' : isEvent ? '--event' : ''}`}>
          <form
            data-testid={`${isSubTask ? 'sub-' : isEvent ? 'event-' : ''}task-form-${nameWatch}`}
            className={`add-edit-task-form ${isSubTask ? 'scroll-y-container-hidden-scroll-bar' : ''}`} 
            onSubmit={taskForm.handleSubmit(onSubmitCreateNewTask)}>
            <div className={`add-edit-task-container--header ${isEditModeOfExistingTask ? 'edit-mode' : 'create-mode'} ${isReadOnly ? 'add-edit-task-container--header--read-only' : ''}`}>
              <AppButton type='button' className='close-modal' onClick={() => onClose()} id={`close-${isEvent ? 'event' : 'task'}-form`}>
                {isEditModeOfExistingTask ?
                  <img src={backIcon} alt="back" />
                  :
                  <>
                    <img className='--desktop' src={closeModalDeskIcon} alt="close" />
                    <img className='--mobile' src={closeModalMobileIcon} alt="close" />
                  </>
                }
              </AppButton>
              {!isEditModeOfExistingTask && <AppButton type='submit' id={`submit-${isEvent ? 'event' : 'task'}-form`} className='save-button' disabled={shouldSaveBtnBeDisabled()} >{t("addNewTaskSubmitButtonText")}</AppButton>}
              {itemForEditing?.isEvent && itemForEditing?.source !== ETaskSource.Internal &&
                <EventExternalSourceDetails eventSource={itemForEditing?.source} />
              }
              {isSubTask && <div className='add-edit-task-container--header--title static-string'>{t("createOrEditSubtaskTitle")}</div>}
              {isSubTask && <div className='parent-task-name-and-tag-container'>
                <h3 className='parent-task-name-header'>{parentName}</h3>
                {parentFirstTag && <SingleTag tag={parentFirstTag} index={0} />}
              </div>}
            </div>
            <section className="details-section">
              <TaskNameController
                onUpdateTask={onUpdateTask}
                onUpdateTaskTextarea={onUpdateTask}
                isDisabled={!!itemForEditing && !!itemForEditing.isMultiWorkTimeTask}
                isReadOnly={isReadOnly}
                isEvent={isEvent}
              />
              <TaskDescription onUpdateTaskTextarea={onUpdateTask} isReadOnly={isReadOnly} isSubTask={isSubTask}/>
              <TaskTags onUpdateTask={onUpdateTask} isSubTask={isSubTask} isReadOnly={isReadOnly} />
              <div className="scheduling-container task-detail-section">
                {!isEvent && <SchedulingSection
                  controlTitle={t("createOrEditDueDatePopupHeaderText")}
                  title={t("addEditTaskDueDateTitle")}
                  formValue={dueDateWatch ? `${getDateWithoutYear(dueDateWatch)}${getTimeByDate(dueDateWatch) !== taskDueDateDefaultTime ? ", " + getTimeByDate(dueDateWatch) : ''}` : ''}
                  SvgIconComponent={DueDateIconComponent}
                  reminderType={"dueDate"}
                  reminderValue={dueDateReminderWatch}
                  task={isEditModeOfExistingTask ? itemForEditing : { ...taskForm.getValues() as ISubTask }}
                  isSubTask={isSubTask}
                  multiWorkTimesSubTasks={null}
                  isReadOnly={isReadOnly}
                  onClick={(e) => createAppOverlayPopover(<TaskEditOverlay
                    isSubTask={isSubTask}
                    overlayType='dueDate'
                    date={taskForm.getValues('dueDate')}
                    reminder={dueDateReminderWatch}
                    onChange={(date, reminder) => onChangeDueDateAndWorkTime("dueDate", date as Date, reminder)} />,
                    isSubTask ? subTaskOverlayPopoverClassName : slideUpFullScreenMobilePopoverClassName,
                    e,
                    isSubTask ? {
                      ...editOverlaysPositionStyle,
                      ...getElementPosition(document.getElementById("add-edit-task-form-container")!),
                      ...getElementWidthAndHeight(document.getElementById("add-edit-task-form-container")!)
                    } : editOverlaysPositionStyle,
                    { isCustomStyle: isSubTask })} />
                }
                <SchedulingSection
                  controlTitle={isEvent ? t("createOrEditEventWorkTimePopupHeaderText") : t("createOrEditWorkTimePopupHeaderText")}
                  title={isEvent ? t("createOrEditEventWorkTimeControlText") : t("addEditTaskWorkDateTimeTitle")}
                  formValue={workTimeWatch ? getDateAndTimeWithoutYear(workTimeWatch) : ''}
                  SvgIconComponent={isEvent ? DueDateIconComponent : WorkTimeIconComponent}
                  reminderType={"workTime"}
                  reminderValue={workTimeReminderWatch}
                  task={isEditModeOfExistingTask ? itemForEditing : { ...taskForm.getValues() as ISubTask }}
                  isSubTask={isSubTask}
                  multiWorkTimesSubTasks={multiWorkTimeSubTasks}
                  setCalendarAsTimePicker={!isEvent ? handlePlannerAsCalendar : undefined}
                  updateWorkTimeToTask={multiWorkTimeSubTasksUpdate}
                  appendWorkTimeToTask={multiWorkTimeSubTasksAppend}
                  removeWorkTimeToTask={multiWorkTimeSubTasksRemove}
                  isReadOnly={isReadOnly}
                  isEvent={isEvent}
                  onClick={(e) =>
                    createAppOverlayPopover(<TaskEditOverlay
                      isSubTask={isSubTask}
                      overlayType={(taskForm.getValues()?.workBlockId || itemForEditing?.workBlockId) ? 'taskWorkBlock' : 'workTime'}
                      taskWorkBlockDetails={{
                        workBlockId: taskForm.getValues()?.workBlockId || itemForEditing?.workBlockId,
                        workBlockInstance: taskForm.getValues()?.workBlockInstance || itemForEditing?.workBlockInstance
                      }}
                      duration={durationWatch}
                      date={taskForm.getValues('workTime')}
                      reminder={workTimeReminderWatch}
                      setCalendarAsTimePicker={!isEvent ? handlePlannerAsCalendar : undefined}
                      taskId={itemForEditing?.taskId}
                      recurrenceValues={{
                        workTimeRecurrenceType: recurrenceValuesWatch?.workTimeRecurrenceType || null,
                        workTimeRecurrenceInterval: recurrenceValuesWatch?.workTimeRecurrenceInterval || null,
                        workTimeRecurrenceWeekDays: recurrenceValuesWatch?.workTimeRecurrenceWeekDays || null,
                      }}
                      onChange={(date, reminder, duration, recurrenceValues, isWorkBlockCleared) => onChangeDueDateAndWorkTime("workTime", date as Date, reminder, duration, recurrenceValues, isWorkBlockCleared)} />,
                      isSubTask ? subTaskOverlayPopoverClassName : slideUpFullScreenMobilePopoverClassName,
                      e,
                      isSubTask ? {
                        ...editOverlaysPositionStyle,
                        ...getElementPosition(document.getElementById("add-edit-task-form-container")!),
                        ...getElementWidthAndHeight(document.getElementById("add-edit-task-form-container")!)
                      } : editOverlaysPositionStyle,
                      { isCustomStyle: isSubTask })} />
              </div>
              {isReadOnly &&
                <ExternalEventsRecurrenceAndReminder
                  reminderValue={workTimeReminderWatch}
                  recurrenceValues={{
                    workTimeRecurrenceType: itemForEditing?.workTimeRecurrenceType || null,
                    workTimeRecurrenceInterval: itemForEditing?.workTimeRecurrenceInterval || null,
                    workTimeRecurrenceWeekDays: itemForEditing?.workTimeRecurrenceWeekDays || null,
                  }}
                  workTimeDate={workTimeWatch}
                />
              }
              {!isEvent && isIncludeSubtasks && !itemForEditing?.parentId &&
                <SubtasksSection
                  subtasksList={subTasksFields}
                  setSubtaskForEditing={setSubtaskForEditing}
                  updateSubtask={subTasksUpdate}
                  appendNewSubtask={subTasksAppend}
                  onRemoveSubTaskFromParentFieldArray={subTasksRemove}
                  isEditModeOfExistingTask={isEditModeOfExistingTask}
                  parentId={itemForEditing?.id}
                />
              }
              <TaskNotes onUpdateTaskTextarea={onUpdateTask} isReadOnly={isReadOnly} />
            </section>
            <section className="action-btn-section">
              {!isReadOnly && isEditModeOfExistingTask &&
                <AppButton
                  type='button'
                  className='delete-item'
                  id={`delete-${isEvent ? 'event' : 'task'}-form-button`}
                  onClick={() => setShouldDisplayDeleteConfirmModal(true)}
                >
                  <img className='trash-mobile' src={trashMobileIcon} alt="trash" />
                </AppButton>
              }
            </section>
          </form>
          {shouldDisplayDeleteConfirmModal &&
            <ConfirmModal
              title={isEvent ? t("deleteEventConfirmModalTitle") : t("deleteTaskConfirmModalTitle")}
              informationText={t("deleteTaskConfirmModalSubTitle")}
              confirmBtnText={t("settingsResetProfileConfirmModalConfirmButtonText") + "."}
              onConfirm={() => onUpdateTask({ status: ETaskStatus.DELETED }, false)}
              onCancel={() => setShouldDisplayDeleteConfirmModal(false)}
              isDisabled={updateTaskRes.status === EAPIStatus.PENDING}
            />}
        </div>
        {timePickerMode && <StagePlanner plannerDisplayType={ETasksGroupsType.MY_WEEK} />}
      </FormProvider>
      {isIncludeSubtasks && !!subtaskForEditing &&
        <SubtaskDetailsModal
          parentName={taskForm.getValues("name") || ""}
          parentDescription={taskForm.getValues("description") || ""}
          parentTags={taskForm.getValues("tags") || []}
          itemForEditing={subtaskForEditing}
          onRemoveSubTaskFromParentFieldArray={subTasksRemove}
          setSubtaskForEditing={setSubtaskForEditing}
          onUpdateSubTaskInParentFieldArr={subTasksUpdate}
        />
      }
    </div>
  )
}

export default CreateOrEditTask;
