import { useCallback, useEffect, useRef } from 'react';

interface ILongPressOptions {
  delay?: number;
}

const useLongPress = ({ delay = 500 }: ILongPressOptions = {}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const start = useCallback((onLongPress: () => void) => {
    timeoutRef.current = setTimeout(() => {
      onLongPress();
    }, delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    return () => clear();
  }, [clear]);

  const bind = (onLongPress: () => void) => {
    return {
      onTouchStart: (e: React.TouchEvent) => {
        e.preventDefault();
        start(onLongPress);
      },
      onTouchEnd: () => {
        clear();
      },
      onTouchCancel: () => {
        clear();
      },
    };
  };

  return bind;
};

export default useLongPress;
