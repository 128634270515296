import React, { FunctionComponent, ReactNode } from 'react'
import { TimePickerContext } from '../../task-edit-overlays/time-picker-context/TimePickerContext'
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import { useTranslation } from 'react-i18next';
import { getDateWithoutYear } from '../../../../../../../shared/utils/dateFormat';
import { setPlannerOutputEventType, setPlannerOutputPayload } from '../../../stage-planner/stagePlanner.store';
import { EPlannerClickEvent } from '../../../stage-planner/stagePlanner.interface';
import './SelectWorkTimeUsingCalendar.scss';
import { getTimeRange } from '../../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { IPlannerCurrentTaskPlacement } from '../../stageTasks.interface';

interface ISelectWorkTimeUsingCalendarPlanner {
  onClose: () => void;
  taskName: string;
}

const SelectWorkTimeUsingCalendarPlanner: FunctionComponent<ISelectWorkTimeUsingCalendarPlanner> = ({ onClose, taskName }) => {
  const { currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getSelectedTime = () => {
    if (!!currentTaskPlacement?.workBlockId && !!currentTaskPlacement?.workBlockStartTime) {
      return `${currentTaskPlacement.workBlockName || t("workBlockDefaultName")} ${getDateWithoutYear(currentTaskPlacement?.workBlockStartTime)}`;
    } else if (currentTaskPlacement?.workTime) {
      return `${getTimeRange(new Date(currentTaskPlacement.workTime), currentTaskPlacement?.duration, true)}, ${getDateWithoutYear(currentTaskPlacement?.workTime)}`;
    }
    return '';
  }

  const onClickSet = () => {
    dispatch(setPlannerOutputEventType(EPlannerClickEvent.TIME_PICKER));
    dispatch(setPlannerOutputPayload({ ...currentTaskPlacement } as IPlannerCurrentTaskPlacement));
  }

  const renderSetBtn = (): ReactNode => {
    return <AppButton id="set-work-time-via-calendar" onClick={onClickSet} disabled={!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockId} className='set-task-time-picker-btn'>{t("setButtonText")}</AppButton>
  }

  return (
    <TimePickerContext onClose={onClose} taskName={taskName} onSet={renderSetBtn()} selectedTimeStr={getSelectedTime()} />
  )
}

export default SelectWorkTimeUsingCalendarPlanner
