import { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react';
import './CalendarDay.scss';
import { TimeIndicator } from '../time-indicator/TimeIndicator';
import recurrenceIcon from '../../../../assets/images/single-task/task_recurrence_icon.svg';
import { store, useAppDispatch, useAppSelector } from '../../../../app/store';
import { setCalendarSelectedWorkTime, setSelectedMainTaskForEditing, setShouldOpenAddEditTaskFrom } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { ETaskFormType, ETaskSource, ETaskStatus, IMessageDataTask } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { addMinutes } from 'date-fns';
import { areDatesEqual, getExternalEventSourceDetails, getWorkBlockAssignedTasksSorted, getWorkBlockOrder, isDesktopView } from '../../../../shared/utils/utils';
import SassVariables from "../../../../styles/style.module.scss";
import { EPlanDayCardDisplayType } from '../../plan-day-card/PlanDayCard';
import { addDaysToDate, getDateBasedOnDayIndexAndWeekOffset } from '../../../../shared/utils/dateFormat';
import createAppOverlayPopover from '../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import PlusButtonOverlay from '../../create-plus-button/plus-button-overlay/PlusButtonOverlay';
import { slideUpHalfScreenMobilePopoverClassName } from '../../../../app/constants';
import { EPlannerMode } from '../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import WorkBlockTasksList from './work-block-tasks-list/WorkBlockTasksList';
import { setShouldOpenWorkBlockDetails, setWorkBlockForEdit } from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';
import { EAPIStatus } from '../../../../shared/api/models';
import { uuid } from '../../../../shared/utils/uuid';
import { onPlaceUnscheduledTask } from '../../plan.utils';
import { isMobileDevice } from '../../../../shared/utils/isMobileDevice';
import useLongPress from '../../../../shared/hooks/useLongPress';

export interface ICalendarEvent {
    id: string;
    parentId?: string | null;
    start: Date;
    end: Date;
    title: string;
    backgroundColor?: string;
    titleColor: string;
    source?: ETaskSource;
    durationType: '30' | 'over-30';
    top: number;
    height: number;
    isRecurring: boolean;
    groupId?: number;
    columnOffset?: number;
    columnsToTake?: number;
    eventsEndAfterCurrentEventStarts?: number;
    totalColumns?: number;
    isEvent?: boolean;
    isWorkBlock?: boolean;
    status: ETaskStatus;
    relatedTasks: IMessageDataTask[];
}

interface IProps {
    tasksAndWorkBlocks: IMessageDataTask[];
    showCurrentTimeIndicator: boolean;
    dayIndex: number;
    cardIndex: number;
    shouldShowHourText: boolean;
    playViewType: EPlanDayCardDisplayType;
    daysToDisplay: number;
    hourHeight?: number;
    nonRelativeDayIndex: number;
}

const defaultHourHeightDesktop = 40;
const defaultHourHeightMobile = 80;

export const CalendarDay = ({ tasksAndWorkBlocks, showCurrentTimeIndicator, dayIndex, cardIndex, shouldShowHourText, playViewType, daysToDisplay, hourHeight = isDesktopView() ? defaultHourHeightDesktop : defaultHourHeightMobile, nonRelativeDayIndex }: IProps) => {
    const { tasksListResponse, updateTaskRes, allTasks } = useAppSelector(store => store.StageTasksReducer);
    const { plannerMode, currentTaskPlacement } = useAppSelector(store => store.StagePlannerReducer);
    const defaultLeftPosition = 40;
    const reductionFromContainerWidth = shouldShowHourText ? 40 : 0;
    const marginBetweenEvents = 2;
    const [startingHour] = useState(0);
    const [endingHour] = useState(24);
    const dispatch = useAppDispatch();
    const [events, setEvents] = useState<ICalendarEvent[][]>(convertTasksToEvents([]));
    const calendarDayContainerRef = useRef<HTMLDivElement>(null);
    const [localRenderTrigger, setLocalRenderTrigger] = useState<string>(uuid());
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const longPressBind = useLongPress();

    useEffect(() => {
        const events = convertTasksToEvents(tasksAndWorkBlocks);
        setEvents(events);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasksAndWorkBlocks, currentTaskPlacement]);

    useEffect(() => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setLocalRenderTrigger(uuid());
        },300);
    },[plannerMode]);


    function convertTasksAndWorkBlocksToEventsList(list: IMessageDataTask[]): ICalendarEvent[] {
        return list.map((el: IMessageDataTask) => {
            const isWorkBlock = el.isEvent && el.isWorkBlock;
            const start = new Date(el.workTime!);
            const end = el.duration && el.duration / 60 > 15 ? addMinutes(start, el.duration / 60) : addMinutes(start, 30);
            const top = (start.getHours() * hourHeight + start.getMinutes() * hourHeight / 60) - (startingHour * hourHeight);
            const height = Math.abs((end.getHours() * hourHeight + end.getMinutes() * hourHeight / 60) - top - (startingHour * hourHeight));
            let backgroundColor = 'transparent';
            if (!el.isWorkBlock) {
                backgroundColor = (el.isEvent && el?.source !== ETaskSource.Internal) ? SassVariables.Neutral3Color : el?.tags && el?.tags?.length > 0 ? el.tags[0].color ? el.tags[0].color === "transparent" ? "#FFF" : el.tags[0].color : "#FFF" : "#FFF";
            }
            return {
                id: el.id!,
                start,
                end,
                top,
                height,
                title: el?.name ? el.name : isWorkBlock ? "Work Block" : "",
                durationType: !el.duration ? '30' : isDesktopView() ? el.duration / 60 > 60 ? 'over-30' : '30' : el.duration / 60 > 30 ? 'over-30' : '30',
                isWorkBlock,
                backgroundColor: backgroundColor,
                titleColor: isWorkBlock ? "white" : backgroundColor !== 'transparent' && backgroundColor !== '#FFF' ? 'white' : SassVariables.MaxDarkColor,
                isRecurring: !!el.workTimeRecurrenceType,
                parentId: el.parentId,
                source: !isWorkBlock ? (el as IMessageDataTask)?.source || ETaskSource.Internal : undefined,
                isEvent: isWorkBlock ? false : el.isEvent || false,
                workBlockId: el?.workBlockId || null,
                status: el?.status || ETaskStatus.NOT_STARTED,
                relatedTasks: isWorkBlock ? getWorkBlockAssignedTasksSorted([...allTasks, currentTaskPlacement || {} as IMessageDataTask], el.id!, start) : [],
            }
        })
    }


    function convertTasksToEvents(tasksAndWorkBlocks: IMessageDataTask[]): ICalendarEvent[][] {
        const tempPlacement = getUnscheduledTaskPlacement();
        const events = [
            ...convertTasksAndWorkBlocksToEventsList(tempPlacement ? [...tasksAndWorkBlocks, tempPlacement] : tasksAndWorkBlocks),
        ];

        events.sort((a, b) => {
            const result = a.start.getTime() - b.start.getTime();
            if (!result) {
                return b.end.getTime() - a.end.getTime();
            }
            return result;
        });
        const overlappingGroups: ICalendarEvent[][] = [];
        let currentGroup: ICalendarEvent[] = [];
        let currentGroupId = 1;

        for (const event of events as ICalendarEvent[]) {
            if (currentGroup.length === 0) {
                event.groupId = currentGroupId;
                currentGroup.push(event as ICalendarEvent);
            }
            else {
                if (currentGroup.find(e => event.start < e.end)) {
                    event.groupId = currentGroupId;
                    currentGroup.push(event as ICalendarEvent);
                } else {
                    overlappingGroups.push(currentGroup);
                    currentGroupId++;
                    event.groupId = currentGroupId;
                    currentGroup = [event as ICalendarEvent];
                }
            }
        }

        if (currentGroup.length > 0) {
            overlappingGroups.push(currentGroup);
        }

        for (const group of overlappingGroups) {
            let maxOffset = 0;
            let currentColumn = 0;

            // create the columns and slot each event in the right column if there is room
            for (let i = 0; i < group.length; i++) {
                if (i === 0) {
                    group[i].columnOffset = 0;
                } else {
                    if (group[i].start >= group[i - 1].end) {
                        group[i].columnOffset = group[i - 1].columnOffset;
                    } else {
                        group[i].columnOffset = currentColumn + 1;
                        currentColumn++;
                    }
                }
            }
            // find the total number of columns for the group
            for (const event of group) {
                if (event.columnOffset! > maxOffset) {
                    maxOffset = event.columnOffset!;
                }
            }

            // set the total number of columns for each group on each event
            for (const event of group) {
                event.totalColumns = maxOffset + 1;
            }
        }

        return overlappingGroups;
    }

    const renderHourLines = () => {
        const lines = [];
        for (let i = startingHour; i <= endingHour; i++) {
            lines.push(
                <div
                    key={i}
                    className='calendar-hour-line'
                    style={{ top: `${i * hourHeight - (startingHour * hourHeight)}px` }}
                    onDoubleClick={() => !isMobileDevice() && handleClickOnHourLine(i % 24, 0)}
                    {...longPressBind(() => handleClickOnHourLine(i % 24, 0))}
                >
                    {shouldShowHourText && i < endingHour &&
                        <span id={`hour-${i}-${cardIndex}`} className='calendar-hour-text'>
                            {i % 12 === 0 ? 12 : i % 12}
                            {i < 12 ? 'A' : 'P'}
                        </span>
                    }
                </div>,
                <div
                    key={i + 0.1}
                    className='calendar-hour-line calendar-hour-line--transparent'
                    style={{ top: `${i * hourHeight - (startingHour * hourHeight)}px`, height: hourHeight / 2, background: "transparent" }}
                    onDoubleClick={() => !isMobileDevice() && handleClickOnHourLine(i % 24, 0)}
                    {...longPressBind(() => handleClickOnHourLine(i % 24, 0))}
                />
            )
            lines.push(
                <div
                    key={i + 0.2}
                    className='calendar-hour-line calendar-hour-line--transparent'
                    style={{ top: `${(i * hourHeight + hourHeight / 2) - (startingHour * hourHeight)}px`, height: hourHeight / 2, background: "transparent" }}
                    onDoubleClick={() => !isMobileDevice() && handleClickOnHourLine(i % 24, 30)}
                    {...longPressBind(() => handleClickOnHourLine(i % 24, 30))}
                />,
                <div
                    key={i + 0.5}
                    className='calendar-hour-line calendar-hour-line--half'
                    style={{ top: `${(i * hourHeight + hourHeight / 2) - (startingHour * hourHeight)}px` }}
                    onDoubleClick={() => !isMobileDevice() && handleClickOnHourLine(i % 24, 30)}
                    {...longPressBind(() => handleClickOnHourLine(i % 24, 30))}
                />)
        }
        return lines;
    }

    const handleClickOnHourLine = (hours: number, minutes: number) => {
        if (!isCalenderDayClickable()) return;
        const currentDate = getDate();
        currentDate.setHours(hours, minutes, 0, 0);
        switch (plannerMode) {
            case EPlannerMode.TIMEPICKER:
            case EPlannerMode.UNSCHEDULEDTASKSPLACER:
                onPlaceUnscheduledTask(currentDate);
                break;
            default:
                onOpenCreateOverlay(currentDate);
                break;
        }
    }

    const onOpenCreateOverlay = (date: Date) => {
        dispatch(setCalendarSelectedWorkTime(date.toISOString()));
        createAppOverlayPopover(
            <PlusButtonOverlay />,
            slideUpHalfScreenMobilePopoverClassName,
        )
    }
    function getDate() {
        switch (playViewType) {
            case EPlanDayCardDisplayType.MY_PLAN:
            case EPlanDayCardDisplayType.MY_DAY:
                return addDaysToDate(new Date(), cardIndex * daysToDisplay + nonRelativeDayIndex);
            case EPlanDayCardDisplayType.MY_WEEK:
                if (daysToDisplay === 7) return getDateBasedOnDayIndexAndWeekOffset(nonRelativeDayIndex, cardIndex);
                return addDaysToDate(new Date(), cardIndex * daysToDisplay + nonRelativeDayIndex);
        }
    }

    const onPlaceTaskInsideWorkBlock = (workBlockEvent: ICalendarEvent) => {
        onPlaceUnscheduledTask(workBlockEvent.start, workBlockEvent.id, workBlockEvent.title, workBlockEvent.isRecurring, workBlockEvent.relatedTasks.length ? getWorkBlockOrder(workBlockEvent.relatedTasks[workBlockEvent.relatedTasks.length - 1]?.workBlockOrder) : 0);
    }

    const onClickWorkBlockEvent = (workBlockEvent: ICalendarEvent) => {
        if (plannerMode === EPlannerMode.TIMEPICKER) {
            onPlaceTaskInsideWorkBlock(workBlockEvent);
            return;
        }
        const workBlock = store.getState().StageTasksReducer.allWorkBlocks.find(w => w.id === workBlockEvent.id && areDatesEqual(w.workTime, workBlockEvent.start));
        if (workBlock) {
            dispatch(setWorkBlockForEdit(workBlock));
            dispatch(setShouldOpenWorkBlockDetails(true));
        }
    }

    const handleEventClick = (clickEvent: MouseEvent<HTMLElement, any>, event: ICalendarEvent, workBlockTask?: IMessageDataTask) => {
        clickEvent.stopPropagation();
        if (!isCalenderDayClickable()) return;
        if (((plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER) && currentTaskPlacement)) {
            if (event.isWorkBlock) {
                onPlaceTaskInsideWorkBlock(event);
                return;
            }
            onPlaceUnscheduledTask(event.start);
            return;
        };
        if (workBlockTask && plannerMode !== EPlannerMode.TIMEPICKER){
            dispatch(setSelectedMainTaskForEditing(workBlockTask));
            dispatch(setShouldOpenAddEditTaskFrom(ETaskFormType.Task));
            return
        }
        if (!event.isWorkBlock && plannerMode === EPlannerMode.TIMEPICKER) {
            onPlaceUnscheduledTask(event.start);
            return;
        }
        if (event.isWorkBlock) onClickWorkBlockEvent(event);
        else {
            const task = store.getState().StageTasksReducer.allTasks.find(t => event.parentId ? t.id === event.parentId : t.id === event.id);
            if (task) {
                dispatch(setSelectedMainTaskForEditing(task));
                dispatch(setShouldOpenAddEditTaskFrom(event.isEvent ? ETaskFormType.Event : ETaskFormType.Task));
            }
        }
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
    }

    const calcEventLeft = (event: ICalendarEvent) => {
        const leftPosition = shouldShowHourText ? defaultLeftPosition : 0;
        if (event.columnOffset === 0) return event.columnOffset! * (calendarDayContainerRef?.current?.clientWidth! - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
        return event.columnOffset! * (calendarDayContainerRef?.current?.clientWidth! - reductionFromContainerWidth) / event.totalColumns! + leftPosition;
    }

    const isCurrentDay = (dayIndex: number) => {
        const currentDate = new Date();
        const cardDate = playViewType === EPlanDayCardDisplayType.MY_WEEK && daysToDisplay === 7 ? getDateBasedOnDayIndexAndWeekOffset(dayIndex, cardIndex) : addDaysToDate(currentDate, cardIndex * daysToDisplay + dayIndex);
        return cardDate.toDateString() === currentDate.toDateString();
    }

    const getEventStyle = (event: ICalendarEvent): CSSProperties => {
        return ({
            position: 'absolute',
            top: event.top,
            left: calcEventLeft(event),
            height: event.height,
            width: (((calendarDayContainerRef?.current?.clientWidth! - reductionFromContainerWidth) / event.totalColumns!) - marginBetweenEvents) + 'px'
        })
    }

    const renderEvents = () => {
        return events.map((group) => {
            return group.map((event) =>
                <div
                    key={event.id}
                    className={`calendar-event calendar-event--${event.durationType} ${event?.isWorkBlock ? 'calendar-event--work-block' : ''}`}
                    onClick={(e) => {e.stopPropagation(); handleEventClick(e, event)}}
                    style={getEventStyle(event)}
                >
                    <div className={`calendar-event-inner-container calendar-event-inner-container--${event.durationType}`} style={{ backgroundColor: event.backgroundColor, border: `${event.backgroundColor === "#FFF" ? "1px solid " + SassVariables.MaxDarkColor : 'none'}` }}>
                        <div className="event-text-container">
                            <div className='title-container'>
                                <h1 className={`calendar-event-title calendar-event-title--${playViewType === EPlanDayCardDisplayType.MY_DAY ? "my-day" : "my-week"} ${event.status === ETaskStatus.DONE ? event.titleColor!=="white" ? 'completed-task completed-task--grey' : 'completed-task' : ''}`} style={{ color: event.titleColor }}>{event.title}</h1>
                            </div>
                            {!event?.isWorkBlock && event.source !== ETaskSource.Internal && <span className='calendar-event-addition-text' style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }}>{getExternalEventSourceDetails(event.source)}</span>}
                            {event?.isWorkBlock && <WorkBlockTasksList workBlock={event} onClickWorkBlockTask={handleEventClick} planViewType={playViewType} relatedTasks={event.relatedTasks} />}
                        </div>
                        {!event?.isWorkBlock && 
                            <div className='calendar-event-addition-info-container'>
                                <img className={`${!event.isRecurring ? ' visibility-hidden' : ''}`} src={recurrenceIcon} alt="recurrence-icon" style={{ filter: event.backgroundColor !== 'transparent' && event.backgroundColor !== '#FFF' ? 'brightness(5)' : 'none' }} />
                            </div>
                        }
                    </div>
                </div>
            )
        }
        );
    }

    function getUnscheduledTaskPlacement() {
        const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;
        if (!currentTaskPlacement || !currentTaskPlacement.workTime) return null;
        const currentDate = getDate();
        const taskDate = new Date(currentTaskPlacement.workTime!);
        if (currentDate.toDateString() === taskDate.toDateString()) return currentTaskPlacement;
        return null;
    }

    const isCalenderDayClickable = () => {
        if (tasksListResponse.status === EAPIStatus.PENDING || updateTaskRes.status === EAPIStatus.PENDING) return false;
        return true;
    }

    return (
        <div className={`calendar-day-container${isCurrentDay(dayIndex) ? ' calendar-day-container--today' : ''}`} style={playViewType === EPlanDayCardDisplayType.MY_WEEK ? { paddingTop: hourHeight / 2 } : {}}>
            <div className='calendar-day-all-day-events-container' style={{ minHeight: hourHeight / 2 }}>
            </div>
            <div ref={calendarDayContainerRef} key={localRenderTrigger} className='calendar-events-container' style={{ height: (endingHour - startingHour) * hourHeight }}>
                {renderHourLines()}
                {calendarDayContainerRef.current && renderEvents()}
                {showCurrentTimeIndicator && <TimeIndicator hourHeight={hourHeight} startingHour={startingHour} />}
            </div>
        </div>
    )
}